import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  delete_one_checkin,
  getDeleteOneCheckInVar,
  getOneCheckInVar,
  get_one_check_in,
  update_one_checkin,
} from "../graphql/checkin";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import { Empty, Card, Row, Col, Button, message, Popconfirm } from "antd";
import { ApolloResult, CheckIn, Clinic, SystemLog, User } from "../store";
import { format } from "date-fns";
import ImageGalleryWithNote from "../components/image_gallery_with_note";
import { Input } from "antd";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import paths from "../routes/paths";
import { delete_one_booking, getDeleteOneBookingVar } from "../graphql/booking";
import { create_one_sys_lg } from "../graphql/system_log";
import withClinic from "../hooks/with_clinic";
interface P {
  currentClinic?: Clinic;
  user?: User;
}

function CheckInDetail(props: P) {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const navigate = useNavigate();
  const { id: chkId = "" } = useParams() as { id?: string };
  const [note, setNote] = useState<string | null>(null);
  const { data, loading, error, refetch } = useQuery<
    ApolloResult<"getCheckIn", CheckIn>
  >(get_one_check_in, { variables: getOneCheckInVar(chkId, clinicId) });
  const [update, { loading: saving }] =
    useMutation<ApolloResult<"updateOneCheckIn", CheckIn>>(update_one_checkin);
  const [deleteOne, { loading: deleting, data: delData }] =
    useMutation<ApolloResult<"deleteOneCheckIn", CheckIn>>(delete_one_checkin);
  const [deleteBooking, { loading: deleting2 }] =
    useMutation<ApolloResult<"deleteOneBooking", { id: string }>>(
      delete_one_booking
    );
  const [creatLog, {}] =
    useMutation<ApolloResult<"createOneSystemLog", SystemLog>>(
      create_one_sys_lg
    );
  useEffect(() => {
    if (data?.getCheckIn) {
      setNote(data.getCheckIn.merchant_note || null);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data || !data.getCheckIn) return <Empty description={"No checkIn"} />;

  const memberName =
    data.getCheckIn.member.clinic_members.length > 0
      ? data.getCheckIn.member.clinic_members[0].name
      : data.getCheckIn.member.name;

  const handleImageUpdates = async (
    images: Array<{
      uid: string;
      url: string;
      type: "old" | "new";
      name: string;
      note?: string;
    }>,
    removes: string[]
  ) => {
    let variables: any = {};
    let connectOrCreate: any[] = images.map((img) => ({
      where: { id: img.uid },
      create: {
        image: img.url,
        thumbnail_image: img.url,
        name: img.name,
        note: img.note,
      },
    }));
    let upsert: any[] = images.map((img) => ({
      where: { id: img.uid },
      create: {
        image: img.url,
        thumbnail_image: img.url,
        name: img.name,
        note: img.note,
      },
      update: { note: { set: img.note } },
    }));
    let disconnect: any[] = removes.map((r) => ({ id: r }));
    variables.where = { id: chkId };
    variables.data = { images: { disconnect, upsert } };
    const result = await update({ variables });
    if (result.data?.updateOneCheckIn) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
  };
  const handleNoteUpdate = async () => {
    let variables: any = {};
    variables.where = { id: chkId };
    variables.data = { merchant_note: { set: note } };
    const result = await update({ variables });
    if (result.data?.updateOneCheckIn) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
  };
  const logging = async (data: {
    logId: string;
    codeId: string;
    metadata: string;
  }) => {
    const { metadata } = data;
    const log_id = data.logId;
    const code = data.codeId;
    const user = { connect: { id: props.user?.userId } };
    const var2 = { data: { code, log_id, metadata, user } };
    const lgResult = await creatLog({ variables: var2 });
    return lgResult.data?.createOneSystemLog || null;
  };
  const handleDelete = async () => {
    // if (data.getCheckIn.booking_id) {
    //     message.error('Operation CheckIn can\'t delete')
    //     return;
    // }
    let success: boolean = false;
    if (data.getCheckIn.booking_id) {
      const variables = getDeleteOneBookingVar(data.getCheckIn.booking_id);
      await deleteBooking({ variables });
    }
    let variables = getDeleteOneCheckInVar(chkId);
    const result = await deleteOne({ variables });
    if (result.data?.deleteOneCheckIn) {
      success = true;
    }
    if (success) {
      message.success("delete success");
      const logId = `${data.getCheckIn.clinic_id}_deleted`;
      const codeId = `checkin_${data.getCheckIn.id}`;
      const metadata = JSON.stringify({
        type: "checkin",
        action: "delete",
        context: data.getCheckIn,
      });
      await logging({ logId, codeId, metadata });
      navigate(paths.checkIns);
    } else {
      message.error("delete failed");
    }
  };
  return (
    <Card
      size="small"
      title={
        <>
          <p>
            Member:{" "}
            <Link to={paths.getMemberDetailRoute(data.getCheckIn.member.id)}>
              {memberName}
            </Link>
          </p>
          <p>
            Service:{" "}
            <Link to={paths.getServiceDetailRoute(data.getCheckIn.service.id)}>
              {data?.getCheckIn?.service?.name}
            </Link>
          </p>
          <p> Therapist: {data?.getCheckIn?.practitioner?.name}</p>
          <p>
            Used Purchase Service:{" "}
            <CheckCircleFilled
              style={{
                color: data.getCheckIn.isUsePurchaseService ? "green" : "gray",
                fontSize: 15,
              }}
            />
          </p>
        </>
      }
      extra={
        <>
          <p>
            CheckIn Time:{" "}
            {data.getCheckIn.in_time &&
              format(new Date(data.getCheckIn?.in_time), "dd MMM,yyyy h:mm a")}
          </p>
          <p>
            CheckOut Time:{" "}
            {data.getCheckIn.out_time &&
              format(new Date(data.getCheckIn?.out_time), "dd MMM,yyyy h:mm a")}
          </p>
          <p> Status: {data?.getCheckIn?.status}</p>
          <p>
            With Booking:{" "}
            <CheckCircleFilled
              style={{
                color: data.getCheckIn.booking_id ? "green" : "gray",
                fontSize: 15,
              }}
            />
          </p>
          {data.getCheckIn.room?.name != null &&
            data.getCheckIn.room?.name != "" && (
              <p> Room: {data.getCheckIn.room?.name}</p>
            )}
        </>
      }
      actions={[
        <Popconfirm
          placement="topRight"
          title={`Sure to delete checkIn ?`}
          onConfirm={handleDelete}
        >
          <Button
            loading={deleting || deleting2}
            icon={<DeleteOutlined />}
            danger
          >
            Delete CheckIn
          </Button>
        </Popconfirm>,
      ]}
    >
      <Row gutter={24}>
        <Col span={16}>
          <Card size="small" title="Photo Gallery">
            <ImageGalleryWithNote
              images={(data.getCheckIn?.images || []).map((img) => ({
                uid: img.id,
                name: img.name,
                url: img.image,
                status: "done",
                note: img.note,
              }))}
              onOk={handleImageUpdates}
              loading={saving}
              clinicId={data.getCheckIn?.clinic_id || ""}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size="small"
            title="Note"
            actions={[
              <Button
                onClick={handleNoteUpdate}
                loading={loading}
                type="primary"
                icon={<SaveOutlined />}
              >
                save
              </Button>,
            ]}
          >
            <Input.TextArea
              value={note}
              rows={5}
              onChange={(e: any) => {
                setNote(e.target.value);
              }}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
}
export default withClinic(CheckInDetail);
