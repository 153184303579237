import { Cascader } from "antd";
import React, { useState } from "react";
import {
  ApolloResult,
  PractitionerSelect,
  RefType,
  SaleChannelInput,
  ServicePackageSelect,
  ServiceSelect,
} from "../../store";
import {
  get_service_list_for_sale_channel,
  get_service_package_for_sale_channel,
  getServicePackageSaleChannelVar,
  getServicesListSaleChannelVar,
} from "../../graphql/service";
import { useLazyQuery } from "@apollo/client";
import {
  get_practitioner_list_for_sale_channel,
  getPractitionerListSaleChannelVar,
} from "../../graphql/practitioner";

interface Props {
  clinicId: string;
  defaultValue: SaleChannelInput["ref"];
  onChange: (value: SaleChannelInput["ref"] | undefined) => void;
  setImage: (value?: string) => void;
}

interface Option {
  value: string;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  image?: string;
}

const optionLists: Option[] = [
  {
    value: RefType.service,
    label: "Service",
    isLeaf: false,
  },
  {
    value: RefType.package,
    label: "Package",
    isLeaf: false,
  },
  // {
  //   value: RefType.doctor,
  //   label: "Doctor",
  //   isLeaf: false,
  // },
];

const RefSelect = (props: Props) => {
  const { clinicId, defaultValue, onChange, setImage } = props;
  const initialValue: string[] | undefined = defaultValue
    ? [defaultValue.ref_type, defaultValue.ref_id]
    : undefined;

  const [options, setOptions] = useState<Option[]>(optionLists);

  const [getServices, { data: serviceData }] = useLazyQuery<
    ApolloResult<"services", ServiceSelect[]>
  >(get_service_list_for_sale_channel);
  const [getServicePackages, { data: servicePackageData }] = useLazyQuery<
    ApolloResult<"servicePackages", ServicePackageSelect[]>
  >(get_service_package_for_sale_channel);
  const [getPractitioners, { data: practitionerData }] = useLazyQuery<
    ApolloResult<"practitioners", PractitionerSelect[]>
  >(get_practitioner_list_for_sale_channel);

  const onSelectChange = (value: string[], selectedOptions: Option[]) => {
    if (value.length !== 2) {
      onChange(undefined);
      return;
    }

    const refType = value[0];
    let ref: SaleChannelInput["ref"] | undefined;

    if (refType === RefType.service) {
      const service = serviceData?.services.find((s) => s.id === value[1]);
      if (service == null) return;
      ref = {
        ref_id: service.id,
        ref_type: RefType.service,
        ref_name: service.name,
        ref_image: service.image,
        ref_description: service.description,
        ref_price: service.price + "",
        ref_original_price: service.original_price + "",
        ref_duration: service.duration,
      };
    } else if (refType === RefType.package) {
      const servicePackage = servicePackageData?.servicePackages.find(
        (s) => s.id === value[1]
      );
      if (servicePackage == null) return;
      ref = {
        ref_id: servicePackage.id,
        ref_type: RefType.package,
        ref_name: servicePackage.name,
        ref_image: servicePackage.image,
        ref_description: servicePackage.description,
        ref_price: servicePackage.price + "",
        ref_original_price: servicePackage.original_price + "",
      };
    } else if (refType === RefType.doctor) {
      const doctor = practitionerData?.practitioners.find(
        (s) => s.id === value[1]
      );
      if (doctor == null) return;
      ref = {
        ref_id: doctor.id,
        ref_type: RefType.doctor,
        ref_name: doctor.name,
        ref_image: doctor.image ?? "",
      };
    }

    if (ref) {
      setImage(ref.ref_image);
      onChange(ref);
    }
  };

  const loadData = async (selectedOptions: Option[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    const targeInex = options.findIndex(
      (option) => option.value === targetOption.value
    );
    if (targeInex === -1) return;

    let leafOptions: Option[] = [];
    if (targetOption.value === RefType.service) {
      const variables = getServicesListSaleChannelVar(clinicId);
      const { data } = await getServices({
        variables: variables,
      });

      if (data?.services == null) return;

      leafOptions = data.services.map((service) => ({
        value: service.id,
        label: service.name,
        isLeaf: true,
      }));
    } else if (targetOption.value === RefType.package) {
      const variables = getServicePackageSaleChannelVar(clinicId);
      const { data } = await getServicePackages({
        variables: variables,
      });

      if (data?.servicePackages == null) return;
      leafOptions = data.servicePackages.map((service) => ({
        value: service.id,
        label: service.name,
        isLeaf: true,
      }));
    } else if (targetOption.value === RefType.doctor) {
      const variables = getPractitionerListSaleChannelVar(clinicId);
      const { data } = await getPractitioners({
        variables: variables,
      });

      if (data?.practitioners == null) return;
      leafOptions = data.practitioners.map((service) => ({
        value: service.id,
        label: service.name,
        isLeaf: true,
      }));
    }

    const newOptions = [...options];
    newOptions[targeInex].children = leafOptions;
    setOptions(newOptions);
  };

  return (
    <Cascader
      style={{ width: 200 }}
      options={options}
      loadData={loadData}
      onChange={onSelectChange}
      changeOnSelect
      defaultValue={initialValue}
    />
  );
};

export default RefSelect as any;
