import { gql } from "@apollo/client";
import { SaleChannelInput } from "../store";

export const sale_channel_list = gql`
  query SaleChannels(
    $where: SaleChannelWhereInput
    $orderBy: [SaleChannelOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    saleChannels(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      channel
      count
      created_at
      id
      order
      original_price
      price
      ref_id
      ref_image
      ref_name
      ref_type
      status
      expire_at
    }
  }
`;

export const getSaleChannelListVar = (data: {
  clinicId: string;
  searchText?: string;
  take: number;
  skip: number;
}) => {
  const variables = {
    where: {
      clinic_id: { equals: data.clinicId },
      ...(data.searchText &&
        data.searchText != "" && {
          OR: [
            { ref_name: { contains: data.searchText } },
            { ref_type: { contains: data.searchText } },
          ],
        }),
    },
    take: data.take,
    skip: data.skip,
    orderBy: [{ order: "asc" }],
  };

  return variables;
};

export const create_sale_channel = gql`
  mutation CreateOneSaleChannel($data: SaleChannelCreateInput!) {
    createOneSaleChannel(data: $data) {
      id
    }
  }
`;

export const update_sale_channel = gql`
  mutation UpdateOneSaleChannel(
    $data: SaleChannelUpdateInput!
    $where: SaleChannelWhereUniqueInput!
  ) {
    updateOneSaleChannel(data: $data, where: $where) {
      id
    }
  }
`;

export const getSaleChannelMetaData = (data: Partial<SaleChannelInput>) => {
  const metadata = {
    ref: {
      ...(data.ref?.ref_description != null && {
        description: data.ref.ref_description,
      }),
      ...(data.ref?.ref_price != null && {
        price: data.ref.ref_price + "",
      }),
      ...(data.ref?.ref_original_price != null && {
        original_price: data.ref.ref_original_price + "",
      }),
      ...(data.ref?.ref_duration != null && {
        duration: data.ref.ref_duration,
      }),
    },
  };

  if (Object.keys(metadata.ref).length === 0) return null;

  return JSON.stringify(metadata);
};

export const getUpdateOneSaleChannelVar = (
  id: string,
  data: Partial<SaleChannelInput>
) => {
  const metadata = getSaleChannelMetaData(data);
  const variables = {
    where: { id },
    data: {
      ...(data?.channel != null && { channel: { set: data.channel } }),
      ...(data?.count != null && { count: { set: data.count } }),
      ...(data?.order != null && { order: { set: data.order } }),
      ...(data?.original_price != null && {
        original_price: { set: data.original_price + "" },
      }),
      ...(data?.price != null && { price: { set: data.price + "" } }),
      ...(data?.ref?.ref_id != null && { ref_id: { set: data.ref.ref_id } }),
      ...(data?.ref?.ref_image != null && {
        ref_image: { set: data.ref.ref_image },
      }),
      ...(data?.ref?.ref_name != null && {
        ref_name: { set: data.ref.ref_name },
      }),
      ...(data?.ref?.ref_type != null && {
        ref_type: { set: data.ref.ref_type },
      }),
      ...(data?.status != null && { status: { set: data.status } }),
      ...(data?.expire_at != null && { expire_at: { set: data.expire_at } }),
      ...(metadata != null && { metadata: { set: metadata } }),
    },
  };

  return variables;
};

export const get_sale_channel_count = gql`
  query _count($where: SaleChannelWhereInput) {
    aggregateSaleChannel(where: $where) {
      _count {
        id
      }
    }
  }
`;

export const detete_sale_channel = gql`
  mutation DeleteOneSaleChannel($where: SaleChannelWhereUniqueInput!) {
    deleteOneSaleChannel(where: $where) {
      id
    }
  }
`;

export const getDeleteOneSaleChannelVar = (id: string) => {
  const variables = {
    where: { id },
  };

  return variables;
};
