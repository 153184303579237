import React, { useState } from "react";
import withClinic from "../hooks/with_clinic";
import { ApolloResult, Booking, CheckIn, Clinic } from "../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../components/date_range";
import { Form } from "antd";
import { Table, Button } from "antd";
import { addDays, format, subDays } from "date-fns";
import { useQuery } from "@apollo/client";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import MultiSelect from "../components/multi_select";
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { getCheckInsVar, get_checkins } from "../graphql/checkin";
import { Link, useNavigate } from "react-router-dom";
import paths from "../routes/paths";
import { ExportExcel } from "../helpers/excel";
import client from "../graphql/apollo_client";
import {
  export_checkinout,
  export_orders,
  getexportCheckInsVar,
} from "../graphql/export";
interface P {
  currentClinic?: Clinic;
}

const CheckInPage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [exporting, setExporting] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 15),
    endDate: addDays(new Date(), 15),
  });
  const [form] = Form.useForm();
  const variables = getCheckInsVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take: 150,
    status: filter,
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"checkIns", CheckIn[]>
  >(get_checkins, { variables });

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };
  const columns: any[] = [
    {
      title: "CheckIn Time",
      dataIndex: "in_time",
      key: "in_time",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        new Date(a.in_time).getTime() - new Date(b.in_time).getTime(),
      render: (v: number, r: CheckIn) => {
        return (
          <Link to={paths.getCheckInDetailRoute(r.id)}>
            {" "}
            {format(new Date(r.in_time), "dd MMM,yyyy h:mm a")}
          </Link>
        );
      },
    },
    {
      title: "CheckOut Time",
      dataIndex: "out_time",
      key: "out_time",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        new Date(a.out_time).getTime() - new Date(b.out_time).getTime(),
      render: (v: number, r: CheckIn) => {
        return (
          <Link to={paths.getCheckInDetailRoute(r.id)}>
            {r.out_time && format(new Date(r.out_time), "dd MMM,yyyy h:mm a")}
          </Link>
        );
      },
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.member.name.toLowerCase().localeCompare(b.member.name.toLowerCase()),
      render: (v: number, r: CheckIn) => {
        return r.member.clinic_members.length > 0
          ? r.member.clinic_members[0].name
          : r.member.name;
      },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: CheckIn, b: CheckIn) => a.status.localeCompare(b.status),
      editable: false,
    },
    {
      title: "Practitioner",
      dataIndex: "practitioner",
      key: "practitioner",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.practitioner.name
          .toLowerCase()
          .localeCompare(b.practitioner.name.toLowerCase()),
      render: (v: number, r: CheckIn) => {
        return r.practitioner.name;
      },
    },
    {
      title: "Helper",
      dataIndex: "Helper",
      key: "service_helper",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.practitioner.name
          .toLowerCase()
          .localeCompare(b.practitioner.name.toLowerCase()),
      render: (v: number, r: CheckIn) => {
        return r?.booking?.service_helper?.name;
      },
    },

    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.service.name
          .toLowerCase()
          .localeCompare(b.service.name.toLowerCase()),
      render: (v: number, r: CheckIn) => {
        return r.service.name;
      },
    },
    {
      title: "Room",
      dataIndex: "room",
      key: "room",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.room?.name
          .toLowerCase()
          .localeCompare(b.room?.name.toLowerCase() ?? ""),
      render: (v: number, r: CheckIn) => {
        return r.room?.name;
      },
    },
    {
      title: "Note",
      dataIndex: "merchant_note",
      key: "merchant_note",
      editable: true,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.merchant_note?.localeCompare(b.merchant_note ?? ""),
      render: (v: string, r: CheckIn) => {
        return (
          <Typography.Paragraph ellipsis={{ rows: 2 }}>
            {r.merchant_note}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: number, r: CheckIn) => {
        return format(new Date(r.in_time), "dd MMM,yyyy ");
      },
    },
    // {
    //     title: 'Action',
    //     key: 'action',
    //     align: 'center',
    //     width: '10%',
    //     dataIndex: 'action',
    //     editable: false,
    //     fixed: 'right',
    //     render: (v: number, record: Account) => {
    //        // const editable = isEditing(record);
    //         return (
    //             <ButtonGroup>
    //                 {editable ?
    //                     (
    //                         saveLoading ? <Button type="primary" icon={<LoadingOutlined />} >saving...</Button> :
    //                             < Popconfirm placement="topRight" title={`Sure to save ${record.account_number}?`} onConfirm={() => handleSave(record)} onCancel={() => setEditingKey('')}>
    //                                 <Button type='primary' icon={<SaveOutlined />} >save</Button>
    //                             </Popconfirm>
    //                     )
    //                     :
    //                     <Button shape="circle" icon={<EditOutlined />}
    //                         onClick={() => edit(record)}
    //                     />
    //                 }
    //             </ButtonGroup >
    //         );

    //     }
    // }
  ];

  const handleExport = async () => {
    const fileName =
      "checkinout_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];

    let list: any[] = [];
    let rolling = true;
    const take = 500;
    let skip = 0;
    const header = [
      "CheckIn Time",
      "CheckOut Time",
      "Member",
      "Status",
      "Practitioner",
      "Helper",
      "Service",
      "Room",
      "Note",
      "Date",
    ];

    setExporting(true);
    while (rolling) {
      // console.log('rolling', skip)
      const variables = getexportCheckInsVar({
        clinicId,
        take,
        skip,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      });
      const result = await client.query<ApolloResult<"checkIns", CheckIn[]>>({
        query: export_checkinout,
        variables,
      });
      const checkIns = result.data?.checkIns || [];
      list = list.concat(checkIns);
      skip += checkIns.length;
      rolling = checkIns.length === take;
    }
    // console.log(list);
    for (const i of list) {
      const id = i.id;
      const member = i.member.clinic;
      const practitioner = i.practitioner.name;
      const status = i.status;
      const service = i.service.name;
      const check_in_time = format(new Date(i.in_time), "yyyy-MMM-dd");
      const check_out_time = i.out_time
        ? format(new Date(i.out_time), "yyyy-MMM-dd")
        : null;
      const helper = i.booking.service_helper;
      const note = i.merchant_note;
      const date = format(new Date(i.created_at), "yyyy-MMM-dd");
      const room = i.room?.name;

      excelData.push({
        check_in_time,
        check_out_time,
        member,
        status,
        practitioner,
        helper,
        service,
        room,
        note,
        date,
      });
    }

    ExportExcel(excelData, fileName, header);
    setExporting(false);
  };

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Check In/Out</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            showHeader={data.checkIns.length > 0}
            //components={{ body: { cell: EditableCell, } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={data.checkIns.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4 }}>
      <Col span={20}>
        <MultiSelect
          selected={filter}
          placeholder="Select checkIn status"
          options={["CHECKIN", "CHECKOUT"]}
          onChange={setFilter}
        />
      </Col>
      <Col span={2}>
        {" "}
        <Button
          size="small"
          icon={<PlusCircleFilled />}
          onClick={() => {
            navigate(paths.newCheckout);
          }}
        >
          Add New
        </Button>
      </Col>
      <Button
        size="small"
        loading={exporting}
        icon={<FileExcelFilled />}
        onClick={() => handleExport()}
      >
        Export
      </Button>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(CheckInPage);
