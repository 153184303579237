import React, { useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Clinic,
  OrderSummary,
  Role,
  SystemUser,
} from "../../store";
import { Row, Col, Typography, Empty } from "antd";
import DateRange from "../../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { addDays, subDays } from "date-fns";
import { useLazyQuery, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import { getOrderSummaryVar, order_summary } from "../../graphql/order";
import { formatMoney, uuidv4 } from "../../helpers/utils";
import { get_users, getUsersVar } from "../../graphql/user";
import { Link } from "react-router-dom";
import paths from "../../routes/paths";

interface Props {
  currentClinic?: Clinic;
}

interface SellerOrderReport {
  sellerId?: string;
  key: string;
  sellerName: string;
  totalRecord: number;
  totalNetAmount: number;
  totalBalance: number;
  totalCreditBalance: number;
  paymentStatus: "PAID" | "UNPAID" | "PARTIAL_PAID";
}

function SellerReport(props: Props) {
  const clinicId = props.currentClinic?.id || "";
  const [form] = Form.useForm();
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 15),
    endDate: addDays(new Date(), 15),
  });

  const {
    loading: loadingUser,
    data: userData,
    error: userError,
  } = useQuery<ApolloResult<"users", Array<SystemUser>>>(get_users, {
    variables: getUsersVar(clinicId, Role.SELLER),
  });

  const [
    getOrderSummary,
    {
      data: orderSummaryData,
      loading: loadingOrderSummary,
      error: orderSummaryError,
    },
  ] =
    useLazyQuery<ApolloResult<"orderSummary", Array<OrderSummary>>>(
      order_summary
    );

  useEffect(() => {
    if (userData && userData.users.length > 0) {
      getOrderSummary({
        variables: getOrderSummaryVar({
          sellers: userData.users.map((u) => u.id),
          fromDate: ranges.startDate,
          toDate: ranges.endDate,
          status: ["ACTIVE"],
          paymentStatus: ["PAID", "UNPAID", "PARTIAL_PAID"],
        }),
        fetchPolicy: "cache-and-network",
      });
    }
  }, [userData, ranges]);

  if (loadingUser || loadingOrderSummary) return <LoadingSpinner />;
  if (userError) return <Error error={userError} />;
  if (orderSummaryError) return <Error error={orderSummaryError} />;
  if (!userData || !orderSummaryData) return <Empty />;

  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };

  const sellerOrderReport: SellerOrderReport[] =
    orderSummaryData &&
    orderSummaryData.orderSummary.length > 0 &&
    userData &&
    userData.users.length > 0
      ? orderSummaryData.orderSummary.map((o) => {
          const sellerName = userData.users.find(
            (u) => u.id === o.seller_id
          )?.display_name;
          return {
            key: uuidv4(),
            sellerId: o.seller_id,
            sellerName: sellerName ?? "All",
            totalNetAmount: o.total_net_amount,
            totalRecord: o.total_records,
            totalBalance: o.total_balance,
            totalCreditBalance: o.total_credit_balance,
            paymentStatus: o.payment_status,
          };
        })
      : [];

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "sellerName",
      key: "sellerName",
      editable: false,
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a: { sellerName: string }, b: { sellerName: string }) =>
        a.sellerName.toLowerCase().localeCompare(b.sellerName.toLowerCase()),
      render: (v: string, r: SellerOrderReport) => {
        if (!r.sellerId) return r.sellerName;
        return <Link to={paths.getSellerSalesRoute(r.sellerId)}>{v}</Link>;
      },
    },
    {
      title: "Order Count",
      dataIndex: "totalRecord",
      key: "totalRecord",
      sorter: (a: { totalRecord: number }, b: { totalRecord: number }) =>
        a.totalRecord - b.totalRecord,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      sorter: (a: { paymentStatus: string }, b: { paymentStatus: string }) =>
        a.paymentStatus.localeCompare(b.paymentStatus),
    },
    {
      title: "Net Amount",
      dataIndex: "totalNetAmount",
      key: "totalNetAmount",
      sorter: (a: { totalNetAmount: number }, b: { totalNetAmount: number }) =>
        a.totalNetAmount - b.totalNetAmount,
      render: (v: number, r: { totalNetAmount: number }) => {
        return `${formatMoney(r.totalNetAmount)} MMK`;
      },
    },
    {
      title: "Balance",
      dataIndex: "totalBalance",
      key: "totalBalance",
      sorter: (a: { totalBalance: number }, b: { totalBalance: number }) =>
        a.totalBalance - b.totalBalance,
      render: (v: number, r: { totalBalance: number }) => {
        return `${formatMoney(r.totalBalance)} MMK`;
      },
    },
    {
      title: "Credit Balance",
      dataIndex: "totalCreditBalance",
      key: "totalCreditBalance",
      sorter: (
        a: { totalCreditBalance: number },
        b: { totalCreditBalance: number }
      ) => a.totalCreditBalance - b.totalCreditBalance,
      render: (v: number, r: { totalCreditBalance: number }) => {
        return `${formatMoney(r.totalCreditBalance)} MMK`;
      },
    },
  ];

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Seller Report</Typography>
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );

  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={loadingUser}
            size="middle"
            showHeader={sellerOrderReport.length > 0}
            dataSource={sellerOrderReport}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
}

export default withClinic(SellerReport);
