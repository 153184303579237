import { useMutation } from '@apollo/client';
import { Button, Col, Form, InputNumber, Modal, Row } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { create_member_ecode, createMemberEcodeVar } from '../graphql/member';
import { ApolloResult, Member, MemberServiceBag, Service } from '../store';
import { DatePicker } from 'antd';
import { Select } from 'antd';
import { Input } from 'antd';
import { Checkbox } from 'antd';
const { Option } = Select


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
interface P {
    title?: string
    bag: MemberServiceBag
    member: Member,
    service: Service,
    clinicId: string,
    onClose: () => void;
    onFinish: () => void;
}
interface FormData {
    count: number;
    expireDate: Date
    channel: string
    ecode?: string
}

const baseChannels: Array<{ id: string, name: string }> = [
    { id: "alifestyleuat", name: "AlifeStyle-UAT", },
    { id: "alifestyleprod", name: "AlifeStyle-PROD" },

];
const MemberEcodeCreate = (props: P) => {
    const formRef = React.useRef<FormInstance<FormData>>(null);
    const [show, setShow] = useState(true);
    const [isCompound, setIsCompound] = useState(false);

    const handleMergeChange = (e: any) => {
        setIsCompound(e.target.checked);
    };

    const [createEcode, { loading, data }] = useMutation<ApolloResult<"ecode", { success: boolean, data: any, message?: string }>>(create_member_ecode);
    const [channels, _] = useState([...baseChannels, { name: "Only Private", id: props.clinicId }])


    useEffect(() => {
        formRef.current?.resetFields();
        formRef.current?.setFieldsValue({
            count: 1,
        });
    }, [props])
    const handleOk = async (val: FormData) => {
        const bagId = props.bag.id;
        const variables = createMemberEcodeVar({
            channel: val.channel,
            count: val.count,
            expireAt: val.expireDate.toISOString(),
            refBy: props.member.name,
            refId: props.bag.id,
            refName: props.service.name,
            ecode: val.ecode
        });
        const result = await createEcode({ variables });
        if (result.data?.ecode) {
            props.onFinish();
            setShow(false);
        }
    }
    return (
        <React.Fragment>
            <Modal
                title={`${props.title || ""}`}
                open={show}
                onCancel={() => setShow(false)}
                footer={[
                    <Button loading={loading} type="primary" form="ecodeForm" key="submit" htmlType="submit">
                        Create
                    </Button>
                ]}
                afterClose={() => {
                    props.onClose();
                }}
                destroyOnClose={true}
                closable={!loading}
                maskClosable={!loading}
            >
                <Row >
                    <Col span={20}>
                        <Form
                            {...layout}
                            id="ecodeForm"
                            ref={formRef}
                            name="control-ref"
                            onFinish={handleOk}
                            style={{}}
                        >
                            <Form.Item
                                label="count"
                                name="count"
                                rules={[{ required: true, message: 'Count is required' }]}
                            >
                                <InputNumber min={0} max={props.bag.remaining_count} />
                            </Form.Item >

                            <Form.Item
                                label="Expiration Date"
                                name="expireDate"
                                rules={[{ required: true, message: 'Expiration date is required' }]}
                            >
                                <DatePicker />
                            </Form.Item>

                            {/* Select Input */}
                            <Form.Item
                                label="Channel"
                                name="channel"
                                rules={[{ required: true, message: 'Please select a channel' }]}
                            >
                                <Select placeholder="Select a channel">
                                    {channels.map(c => (<Option value={c.id}>{c.name}</Option>))}
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Checkbox onChange={handleMergeChange}>
                                    Do you want to compound with an existing ECode?
                                </Checkbox>
                            </Form.Item>
                            {isCompound &&
                                <Form.Item
                                    label={"Base Ecode"}
                                    name="ecode"
                                    rules={[{ required: true, message: 'Base ecode is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            }
                        </Form>
                    </Col>
                </Row>
            </Modal >
        </React.Fragment >
    );
};

export default MemberEcodeCreate;