import { gql } from "@apollo/client";

export const get_checkins = gql`
  query CheckIns(
    $where: CheckInWhereInput
    $orderBy: [CheckInOrderByWithRelationInput!]
    $take: Int
    $clinicMembersWhere2: ClinicMemberWhereInput
  ) {
    checkIns(where: $where, orderBy: $orderBy, take: $take) {
      id
      clinic_id
      in_time
      created_at
      out_time
      status
      member_id
      member {
        name
        id
        clinic_members(where: $clinicMembersWhere2) {
          name
          clinic_id
        }
      }
      practitioner_id
      practitioner {
        name
        id
      }
      service_id
      service {
        name
        id
      }
      merchant_note
      booking {
        service_helper {
          id
          name
        }
      }
      room {
        name
      }
    }
  }
`;

export const getCheckInsVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[] | string;
  searchText?: string | null;
  take: number;
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({
      member: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({
      practitioner: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({
      booking: {
        is: {
          OR: [
            {
              service_helper: {
                is: { OR: [{ name: { contains: searchText } }] },
              },
            },
          ],
        },
      },
    });
    OR.push({ service: { is: { OR: [{ name: { contains: searchText } }] } } });
  }
  where.clinic_id = { equals: data.clinicId };
  where.in_time = { gte: data.startDate, lte: data.endDate };
  variables.clinicMembersWhere2 = { clinic_id: { equals: data.clinicId } };
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = 100;
  return variables;
};

export const get_members_checkin_count = gql`
  query GroupByCheckIn(
    $by: [CheckInScalarFieldEnum!]!
    $where: CheckInWhereInput
  ) {
    groupByCheckIn(by: $by, where: $where) {
      _count {
        _all
      }
      member_id
      clinic_id
    }
  }
`;
export const getMembersCheckInCountVar = (data: {
  clinicId: string;
  memberIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.by = ["clinic_id", "member_id"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    member_id: { in: data.memberIds },
  };
  return variables;
};

export const create_one_checkin = gql`
  mutation CreateOneCheckIn($data: CheckInCreateInput!) {
    createOneCheckIn(data: $data) {
      id
    }
  }
`;
export const getCreateOneCheckInVar = (data: {
  clinicId: string;
  practitionerId: string;
  serviceId: string;
  memberId: string;
  userId: string;
  inTime: Date;
  outTime: Date;
}) => {
  let variables: any = {};
  const clinic = { connect: { id: data.clinicId } };
  const practitioner = { connect: { id: data.practitionerId } };
  const service = { connect: { id: data.serviceId } };
  const member = { connect: { id: data.memberId } };
  const user = { connect: { id: data.userId } };
  const in_time = data.inTime;
  const out_time = data.outTime;
  const status = "CHECKOUT";
  variables.data = {
    in_time,
    out_time,
    status,
    clinic,
    service,
    practitioner,
    member,
    user,
  };
  return variables;
};

export const get_one_check_in = gql`
  query GetCheckIn(
    $where: CheckInWhereUniqueInput!
    $clinicMembersWhere2: ClinicMemberWhereInput
  ) {
    getCheckIn(where: $where) {
      id
      status
      created_at
      in_time
      out_time
      merchant_note
      clinic_id
      isUsePurchaseService
      service {
        id
        name
      }
      booking_id
      booking {
        from_time
        to_time
        status
      }
      member {
        id
        name
        clinic_members(where: $clinicMembersWhere2) {
          name
        }
      }
      images {
        id
        name
        note
        image
      }
      practitioner {
        id
        name
      }
      room {
        name
      }
    }
  }
`;
export const getOneCheckInVar = (id: string, clinicId: string) => ({
  where: { id },
  clinicMembersWhere2: { clinic_id: { equals: clinicId } },
});

export const update_one_checkin = gql`
  mutation UpdateOneCheckIn(
    $data: CheckInUpdateInput!
    $where: CheckInWhereUniqueInput!
  ) {
    updateOneCheckIn(data: $data, where: $where) {
      id
    }
  }
`;
export const getUpdateOneCheckInVar = (id: string) => ({ where: { id } });

export const delete_one_checkin = gql`
  mutation DeleteOneCheckIn($where: CheckInWhereUniqueInput!) {
    deleteOneCheckIn(where: $where) {
      id
    }
  }
`;
export const getDeleteOneCheckInVar = (id: string) => ({ where: { id } });
