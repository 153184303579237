import { gql } from "@apollo/client";
import { id } from "date-fns/locale";
import { VariablesAreInputTypesRule } from "graphql";
import { orderBy } from "lodash";

export const get_services = gql`
  query Services(
    $where: ServiceWhereInput
    $orderBy: [ServiceOrderByWithRelationInput!]
    $take: Int
  ) {
    services(where: $where, orderBy: $orderBy, take: $take) {
      id
      image
      clinic_id
      name
      original_price
      price
      description
      status
      created_at
      sort_order
      tax
      duration
      commission
      max_duration_count
    }
  }
`;
export const getServicesVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[];
  searchText?: string | null;
  take: number;
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({ name: { contains: searchText } });
  }
  where.clinic_id = { equals: data.clinicId };
  // where.created_at = { gte: data.startDate, lte: data.endDate };
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = 150;
  return variables;
};

export const get_services_for_Practitioner = gql`
  query Services($where: ServiceWhereInput) {
    services(where: $where) {
      image
      id
      name
      duration
    }
  }
`;

export const getServicesForPractitionerVar = (data: {
  clinicId: string;
  practitionerId: string;
}) => ({
  variables: {
    where: {
      clinic_id: {
        equals: data.clinicId,
      },
      practitioners: {
        every: {
          id: {
            notIn: [data.practitionerId],
          },
        },
      },
    },
  },
});

export const get_service_packages = gql`
  query ServicePackages(
    $where: ServicePackageWhereInput
    $orderBy: [ServicePackageOrderByWithRelationInput!]
    $take: Int
  ) {
    servicePackages(where: $where, orderBy: $orderBy, take: $take) {
      id
      image
      name
      price
      original_price
      status
      sort_order
      tax
      description
      clinic_id
      expiry_day
      created_at
      isLock
    }
  }
`;

export const get_service_package_for_sale_channel = gql`
  query ServicePackages(
    $where: ServicePackageWhereInput
    $orderBy: [ServicePackageOrderByWithRelationInput!]
  ) {
    servicePackages(where: $where, orderBy: $orderBy) {
      id
      name
      image
      description
      price
      original_price
    }
  }
`;

export const getServicePackageSaleChannelVar = (clinicId: string) => {
  const variable = {
    where: {
      clinic_id: { equals: clinicId },
      status: { equals: "ACTIVE" },
    },
    orderBy: [{ name: "asc" }],
  };

  return variable;
};

export const get_service_psi = gql`
  query ServiceProductStockItems(
    $where: ServiceProductStockItemWhereInput
    $orderBy: [ServiceProductStockItemOrderByWithRelationInput!]
    $take: Int
  ) {
    serviceProductStockItems(where: $where, orderBy: $orderBy, take: $take) {
      id
      name
      status
      stock
      amount_per_product
      product_stock_item_id
      product_stock_item {
        id
        name
      }
      created_at
    }
  }
`;

export const getServiePsiVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[];
  searchText?: string | null;
  take: number;
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({ name: { contains: searchText } });
  }
  where.product_stock_item = { is: { clinic_id: { equals: data.clinicId } } };
  // where.created_at = { gte: data.startDate, lte: data.endDate };
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = 150;
  return variables;
};

export const get_service_psi_usage = gql`
  query ServiceProductStockItemUsages(
    $where: ServiceProductStockItemUsageWhereInput
    $orderBy: [ServiceProductStockItemUsageOrderByWithRelationInput!]
    $take: Int
  ) {
    serviceProductStockItemUsages(
      where: $where
      orderBy: $orderBy
      take: $take
    ) {
      id
      amount_per_service
      service_id
      service {
        id
        name
        image
      }
      created_at
      service_product_stock_item_id
      service_product_stock_item {
        name
        id
      }
    }
  }
`;

export const getServicePsiUsageVar = (data: {
  clinicId: string;
  searchText?: string | null;
  take: number;
}) => {
  const { searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({ service: { is: { name: { contains: searchText } } } });
    OR.push({
      service_product_stock_item: { is: { name: { contains: searchText } } },
    });
  }
  where.service = { is: { clinic_id: { equals: data.clinicId } } };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = 150;
  return variables;
};

export const get_one_service = gql`
  query Service($where: ServiceWhereUniqueInput!) {
    service(where: $where) {
      id
      image
      name
      original_price
      price
      tax
      status
      form_type_id
      description
      ratings {
        rate
        note
        created_at
        member {
          name
          id
          image
        }
      }
      practitioners {
        id
        name
      }
      service_types {
        id
        name
      }
      rooms {
        id
        name
      }
    }
  }
`;

export const update_one_service = gql`
  mutation UpdateOneService(
    $data: ServiceUpdateInput!
    $where: ServiceWhereUniqueInput!
  ) {
    updateOneService(data: $data, where: $where) {
      id
    }
  }
`;
export const getUpdateOneServiceVar = (
  id: string,
  data: any,
  connectors?: any
) => {
  let variables: any = {},
    update: any = {};
  variables.where = { id };
  if (typeof data === "object") {
    update = Object.assign(
      {},
      ...Object.keys(data).map((k, i) => ({ [k]: { set: data[k] } }))
    );
  }
  variables.data = update;
  if (connectors && typeof connectors === "object") {
    variables.data = { ...variables.data, ...connectors };
  }
  return variables;
};

export const delete_one_service = gql`
  mutation UpdateOneService(
    $data: ServiceUpdateInput!
    $where: ServiceWhereUniqueInput!
    $deleteOneServiceWhere2: ServiceWhereUniqueInput!
  ) {
    updateOneService(data: $data, where: $where) {
      id
    }
    deleteOneService(where: $deleteOneServiceWhere2) {
      id
    }
  }
`;

export const getDeleteOneSrvVar = (
  serviceId: string,
  opts: { practionIds: string[]; serviceTypeIds: string[] }
) => {
  let variables: any = {};
  variables = {
    data: {
      bookings: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      checkins: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      child_services: {
        deleteMany: [
          {
            parent_service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      images: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      member_service_bags: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      order_items: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      ratings: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      service_package_items: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      service_product_stock_item_usages: {
        deleteMany: [
          {
            service_id: {
              equals: serviceId,
            },
          },
        ],
      },
      parent_service: {
        disconnect: true,
      },
      practitioners: {
        disconnect: opts.practionIds.map((id) => ({ id })),
      },
      service_types: {
        disconnect: opts.serviceTypeIds.map((id) => ({ id })),
      },
    },
    where: {
      id: serviceId,
    },
    deleteOneServiceWhere2: {
      id: serviceId,
    },
  };
  return variables;
};

export const get_service_list_for_sale_channel = gql`
  query Services(
    $where: ServiceWhereInput
    $orderBy: [ServiceOrderByWithRelationInput!]
  ) {
    services(where: $where, orderBy: $orderBy) {
      id
      name
      image
      description
      price
      original_price
      duration
    }
  }
`;

export const getServicesListSaleChannelVar = (clinicId: string) => {
  const variable = {
    where: {
      clinic_id: {
        equals: clinicId,
      },
      status: {
        equals: "ACTIVE",
      },
    },
    orderBy: [
      {
        name: "asc",
      },
    ],
  };
  return variable;
};
