const paths = {
  home: "/home",
  login: "/login",
  services: "/services",
  sales: "/sales",
  appointments: "/appointments",
  checkIns: "/check_ins",
  checkIn: "/check_in/:id",
  helper: "/helper",
  getCheckInDetailRoute: (id: string) => `/check_in/${id}`,
  service_packages: "/service_packages",
  service_categories: "/service_categories",
  service_stock_items: "/service_stock_items",
  service_stock_usages: "/service_stock_usages",
  therapists: "/therapists",
  therapist_detail: "/therapist_detail/:id",
  getTherapistDetailRoute: (therapistId: string) =>
    `/therapist_detail/${therapistId}`,
  products: "/products",
  product_stock_items: "/product_stock_items",
  report: "/report",
  daily_sales: "/daily_sales",
  treatment_report: "/treatment_report",
  practitioner_treatment_report: "/practitioner_treatment_report/:id",
  getPractitionerTreatmentReport: (practitionerId: string) =>
    `/practitioner_treatment_report/${practitionerId}`,
  top_treatment_report: "/top_treatment_report",
  top_practitioner_report: "/top_practitioner_report",
  top_member_report: "/top_member_report",
  seller_report: "/seller_report",
  seller_sales: "/seller_sales/:id",
  getSellerSalesRoute: (sellerId: string) => `/seller_sales/${sellerId}`,
  therapist_sales: "/therapist_sales",
  members: "/members",
  offer_categories: "/offer_categories",
  offers: "/offers",
  test_p: "/test_p",
  member_detail: "/member_detail/:id",
  getMemberDetailRoute: (memberId: string) => `/member_detail/${memberId}`,
  notifications: "/notifications",
  leaves: "/leaves",
  user: "/user",
  service_detail: "/service/:id",
  getServiceDetailRoute: (srvId: string) => `/service/${srvId}`,
  memberImport: "/member_import",
  clinic: "/clinic",
  banks: "/banks",
  newSale: "/new_sale",
  newCheckout: "/new_checkout",
  serviceRooms: "/service_rooms",
  serviceRoomDetail: "/service_room/:id",
  getServiceRoomDetailRoute: (roomId: string) => `/service_room/${roomId}`,
  getServiceHelperDetailRoute: (helperId: string) =>
    `/service_helper/${helperId}`,
  saleDetail: "/sale/:id",
  getSaleDetailRoute: (saleId: string) => `/sale/${saleId}`,
  wallets: "/wallets",
  transactions: "/transactions",
  service_record_froms: "/service_record_froms",
  service_consent_froms: "/service_consent_froms",
  customerBehaviourDashboard: "/bi_customer_behaviour",
  serviceBehaviourDashborad: "/bi_service_behaviour",
  sale_channel: "/sale_channel",
};
export default paths;
