import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Empty,
  List,
  Popconfirm,
  Typography,
  message,
} from "antd";
import { format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import ErrorC from "../components/apollo_error";
import LoadingSpinner from "../components/loading_indicator";
import {
  cancel_order,
  getCancelOrderVar,
  getOneOrderVar,
  get_one_order,
} from "../graphql/order";
import { formatMoney } from "../helpers/utils";
import withClinic from "../hooks/with_clinic";
import paths from "../routes/paths";
import { ApolloResult, Clinic, Order, OrderItem, User } from "../store";
import { json } from "stream/consumers";
interface P {
  currentClinic?: Clinic;
  user?: User;
}

function SaleDetail(props: P) {
  const userId = props.user?.userId || "";
  const clinicId = props.currentClinic?.id || "";
  const navigate = useNavigate();
  const { id: orderId = "" } = useParams() as { id?: string };
  const { data, loading, error, refetch } = useQuery<
    ApolloResult<"getOrder", Order>
  >(get_one_order, { variables: getOneOrderVar(orderId, clinicId) });
  const [cancelOrder, { loading: saving }] =
    useMutation<ApolloResult<"fallbackSaleOrder", Order>>(cancel_order);
  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorC error={error} />;
  if (!data || !data.getOrder) return <Empty description={"No Order"} />;

  const note = JSON.parse(data.getOrder?.metadata || "{}")?.merchant_note;

  const handleCancel = async () => {
    const variables = getCancelOrderVar({ userId, orderId });
    try {
      const result = await cancelOrder({ variables });
      if (result.data?.fallbackSaleOrder) {
        message.success("cancel success");
        navigate(paths.sales);
      } else {
        if (result.errors && result.errors?.length > 0) {
          const err = result.errors[0].message;
          message.error(`failed: ${err}`);
        } else {
          message.error(`failed`);
        }
      }
    } catch (error) {
      let msg = "Unknown Error";
      if (error instanceof Error) msg = error.message;
      // we'll proceed, but let's report it
      message.error(msg);
    }
  };
  const getMemberName = () => {
    if (!data) return "";
    const name =
      data.getOrder.member.clinic_members.length > 0
        ? data.getOrder.member.clinic_members[0].name
        : data.getOrder.member.name;
    return name;
  };
  return (
    <>
      <Card
        size="small"
        type="inner"
        title={
          <>
            <p>
              OrderNo: {data?.getOrder.order_id} | Date:{" "}
              {format(new Date(data.getOrder.created_at), "dd MMM,yyyy")} |" "
              {data.getOrder.status} | {data.getOrder.payment_method} |" "
              <Link to={paths.getMemberDetailRoute(data.getOrder.member_id)}>
                {getMemberName()}
              </Link>
            </p>
          </>
        }
        extra={
          data.getOrder.status !== "CANCEL" &&
          Number(data.getOrder.total) > 0 && [
            <Popconfirm
              placement="topRight"
              title={`Sure to cancel ${data.getOrder.order_id} ?`}
              onConfirm={handleCancel}
            >
              <Button loading={saving} icon={<DeleteOutlined />} danger>
                Cancel
              </Button>
            </Popconfirm>,
          ]
        }
        actions={[
          <List size="small">
            {data.getOrder.seller != null &&
              data.getOrder.seller.display_name != null &&
              data.getOrder.seller.display_name != "" && (
                <List.Item
                  actions={[
                    <Typography style={{ width: 200, textAlign: "right" }}>
                      {data.getOrder.seller.display_name}
                    </Typography>,
                  ]}
                >
                  <List.Item.Meta />
                  <Typography.Paragraph
                    style={{ minWidth: 100, textAlign: "left" }}
                  >
                    Seller
                  </Typography.Paragraph>
                </List.Item>
              )}
            <List.Item
              actions={[
                <Typography style={{ width: 200, textAlign: "right" }}>
                  {formatMoney(Number(data.getOrder.total))} MMK
                </Typography>,
              ]}
            >
              <List.Item.Meta />
              <Typography.Paragraph
                style={{ minWidth: 100, textAlign: "left" }}
              >
                Subtotal
              </Typography.Paragraph>
            </List.Item>
            <List.Item
              actions={[
                <Typography style={{ width: 200, textAlign: "right" }}>
                  {Number(data.getOrder.tax) > 0
                    ? `${formatMoney(Number(data.getOrder.tax))} MMK`
                    : "-"}
                </Typography>,
              ]}
            >
              <List.Item.Meta />
              <Typography.Paragraph
                style={{ minWidth: 100, textAlign: "left" }}
              >
                Tax
              </Typography.Paragraph>
            </List.Item>
            <List.Item
              actions={[
                <Typography style={{ width: 200, textAlign: "right" }}>
                  {Number(data.getOrder.discount) > 0
                    ? `${formatMoney(Number(data.getOrder.discount))} MMK`
                    : "-"}
                </Typography>,
              ]}
            >
              <List.Item.Meta />
              <Typography.Paragraph
                style={{ minWidth: 100, textAlign: "left" }}
              >
                Discount
              </Typography.Paragraph>
            </List.Item>
            <List.Item
              actions={[
                <Typography
                  style={{
                    width: 200,
                    textAlign: "right",
                  }}
                >
                  {`${formatMoney(Number(data.getOrder.net_total))} MMK`}
                </Typography>,
              ]}
            >
              <List.Item.Meta />
              <Typography.Paragraph
                style={{ minWidth: 100, textAlign: "left" }}
              >
                Total Amount
              </Typography.Paragraph>
            </List.Item>
          </List>,
        ]}
      >
        <List
          size="small"
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          dataSource={data.getOrder.order_items}
          renderItem={(item: OrderItem) => (
            <List.Item
              actions={[
                <Typography>{formatMoney(Number(item.total))} MMK</Typography>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Badge count={item.quantity} color="blue">
                    <Avatar
                      src={
                        item.service?.image || item.service_package?.image || ""
                      }
                    />
                  </Badge>
                }
                title={
                  <Typography.Title level={5}>
                    {item.service?.name ||
                      item.service_package?.name ||
                      item.product_stock_item?.name ||
                      ""}
                  </Typography.Title>
                }
                description={
                  <Typography.Paragraph>
                    {item.service ? (
                      <Link to={paths.getServiceDetailRoute(item.service_id!)}>
                        service
                      </Link>
                    ) : item.service_package_id ? (
                      "service package"
                    ) : (
                      "product"
                    )}
                  </Typography.Paragraph>
                }
              />
              <Typography.Paragraph
                style={{ minWidth: 250, textAlign: "left" }}
              >
                {formatMoney(Number(item.price))} x {item.quantity}
              </Typography.Paragraph>
            </List.Item>
          )}
        />
      </Card>
      {note != null && note != "" && (
        <div
          style={{
            width: "80%",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <div
            style={{
              backgroundColor: "#fafafa",
              padding: "20px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Typography.Title
              level={5}
              style={{
                marginTop: 0,
                marginBottom: "12px",
                color: "#1F2937",
                textAlign: "left",
              }}
            >
              Note
            </Typography.Title>
            <Typography.Paragraph
              style={{
                marginBottom: 0,
                lineHeight: "1.6",
                color: "#374151",
                textAlign: "left",
              }}
            >
              {note}
            </Typography.Paragraph>
          </div>
        </div>
      )}
    </>
  );
}
export default withClinic(SaleDetail);
